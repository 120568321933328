import { Suspense, lazy } from "react";
import { Router, Redirect } from "@reach/router";

const Home = lazy(() =>
    import(/* webpackChunkName: "view-web-Home" */ "./home")
);

const HomeMenu = () => (
    <Suspense fallback={<div className="loading" />}>
        <Router>
            <Redirect from="/" to="view" noThrow />
            <Home path='view' />
        </Router>
    </Suspense>
);
export default HomeMenu;
